<template lang="pug">
  v-container(fluid)
    v-row(justify='end')
      v-btn(
        @click='reportType === "analytics" ? getAnalyticsRequest() : getForecastRequest()',
        width='140'
        elevation='0',
        color='accent'
      ) Получить отчет
      v-btn.ml-4.mr-3(elevation='0', outlined, color='accent', disabled) Шаблоны
    v-row
      v-col(cols='10')
        v-row
          v-col.d-flex.justify-center
            v-select(
              v-model='reportType',
              :items='reports',
              item-value='value',
              item-text='label',
              :menu-props='{ bottom: true, offsetY: true }',
              dense,
              outlined,
              hide-details,
              label='Тип',
              append-icon='mdi-chevron-down',
            )
          v-col(align-self='end')
            v-menu(
              ref='menu',
              v-model='menu',
              :close-on-content-click='false',
              transition='scale-transition',
              offset-y,
              min-width='auto'
            )
              v-date-picker(
                header-color='accent',
                v-model='date',
                :max='new Date().toISOString().substr(0, 10)',
                show-adjacent-months,
                first-day-of-week="1"
                range,
                locale='ru-ru'
              )
              template(v-slot:activator='{ on, attrs }')
                v-text-field(
                  style='margin-left: -3px',
                  v-model='dateFormatted',
                  label='Период',
                  prepend-icon='mdi-calendar',
                  readonly='',
                  v-bind='attrs',
                  v-on='on',
                  dense,
                  outlined,
                  hide-details
                )
        v-row(v-if='["root", "agency"].includes(interfaceName)')
          v-col
            v-menu(offset-y, :close-on-content-click='false')
              template(v-slot:activator='{ on, attrs }')
                v-btn.dropdown_button(
                  color='primary',
                  outlined,
                  v-bind='attrs',
                  v-on='on',
                  width='100%',
                  :retain-focus-on-click='true'
                )
                  | Рекламодатель {{ campaign !== '' ? '(1)' : '' }}
                  v-icon.dropdown_button_icon(
                    :class='{ filter_btn_active_icon: attrs["aria-expanded"] === "true" }'
                  ) mdi-chevron-down
              v-list
                v-list-item(v-if='interfaceName === "root"')
                  v-select(
                    label='Агенство',
                    v-model='agency',
                    :items='getAgencies',
                    item-value='id',
                    item-text='name',
                    :menu-props='{ bottom: true, offsetY: true }',
                    dense,
                    outlined,
                    hide-details,
                    append-icon='mdi-chevron-down',
                    @change='changeAgency'
                  )
                v-list-item(v-if='["root", "agency"].includes(interfaceName)')
                  v-select(
                    label='Рекламодатель',
                    v-model='advertiser',
                    :items='getAccountData',
                    item-value='id',
                    item-text='name',
                    :menu-props='{ bottom: true, offsetY: true }',
                    dense,
                    outlined,
                    hide-details,
                    append-icon='mdi-chevron-down',
                    @change='changeAdvertiser'
                  )
        template(v-if='reportType === "analytics"')
          v-row
            v-col
              v-select(
                clearable,
                v-model='group',
                :items='groups',
                item-value='value',
                item-text='label',
                :menu-props='{ bottom: true, offsetY: true }',
                multiple,
                dense,
                outlined,
                hide-details,
                append-icon='mdi-chevron-down',
                label='Группировка'
              )
            v-col
              .field
                .filters__container
                  .filter_btn(
                    @click='filter_menu = !filter_menu',
                    :class='{ filter_btn_active: filters.length }'
                  )
                    | Фильтры {{ filters.length ? `(${filters.length})` : "" }}
                    v-icon.filter_btn_icon(:class='{ filter_btn_active_icon: filter_menu }') mdi-chevron-down
                  v-card.filters(v-if='filter_menu', v-click-outside='onClickOutside')
                    v-row
                      v-col
                        v-btn(@click='filters.push({ field: "", operation: "", values: [] })') Добавить
                      v-col.d-flex.justify-end
                        v-btn(@click='filters = []', text, color='error') X Сбросить
                    v-row(v-for='(filter, index) in filters', :key='index')
                      v-col(cols='10')
                        v-row
                          v-col
                            v-select(
                              v-model='filter.field',
                              :items='filter_types',
                              :menu-props='{ bottom: true, offsetY: true }',
                              item-value='value',
                              item-text='label',
                              dense,
                              outlined,
                              hide-details,
                              append-icon='mdi-chevron-down',
                              @change='onFilterChange($event, index)'
                            )
                          v-col
                            v-select(
                              v-model='filter.operation',
                              :items='operations',
                              item-value='value',
                              item-text='label',
                              :menu-props='{ bottom: true, offsetY: true }',
                              dense,
                              outlined,
                              hide-details,
                              append-icon='mdi-chevron-down',
                            )
                          v-col(v-if='filterSelectFields.includes(filter.field)')
                            v-select(
                              v-model='filter.values',
                              :items='filter_values[filter.field]',
                              item-value='id',
                              item-text='name',
                              :menu-props='{ bottom: true, offsetY: true }',
                              multiple,
                              dense,
                              outlined,
                              hide-details,
                              append-icon='mdi-chevron-down',
                            )
                          v-col(v-else)
                            v-text-field(
                              v-model='filter.values[0]',
                              multiple,
                              dense,
                              outlined,
                              hide-details,
                              :disabled='filter.field === "event"'
                            )
                      v-col
                        v-btn(@click='deleteFilter(index)', text, color='error')
                          v-icon mdi-close-circle-outline

        template(v-else)
          v-row
            v-col(cols='6')
              v-select(
                v-model='city',
                :items='getCities',
                item-value='id',
                item-text='name',
                :menu-props='{ bottom: true, offsetY: true }',
                dense,
                outlined,
                hide-details,
                label='Город',
                clearable,
                append-icon='mdi-chevron-down',
                @change='surface_ids = [""]'
              )
          v-row
            v-col(cols='6')
              v-select(
                v-model='supplier',
                :items='getPublishers',
                item-value='id',
                item-text='name',
                :menu-props='{ bottom: true, offsetY: true }',
                dense,
                outlined,
                hide-details,
                multiple,
                append-icon='mdi-chevron-down',
                label='Поставщик поверхности'
              )
            v-col(cols='6')
              v-row(v-for='(surface_id, key) in surface_ids', :key='key')
                v-col.identifiers
                  v-text-field(
                    style='margin-left: -3px',
                    v-model='surface_ids[key]',
                    label='Укажите идентификатор поверхности',
                    dense,
                    outlined,
                    hide-details
                  )
                  .add_button(v-if='key === 0', @click='!city ? surface_ids.push("") : null')
                    v-icon(style='color: #fff; cursor: pointer') mdi-plus
                  div(v-else, @click='$delete(surface_ids, key)')
                    v-icon(style="cursor: pointer;") mdi-close
      v-col.d-flex.align-end.justify-end
        export_table(:data='getAnalytics', :filename='reportType')
          v-btn(elevation='0', color='accent')
            v-icon download
    v-data-table.elevation-1.mt-4(
      :headers='getHeaders',
      :items='analyticsFormatted',
      :loading='isLoading',
      loading-text='Данные загружаются, ожидайте',
      :footer-props='{"items-per-page-text": "Записей на страницу", pageText: "{0}-{1} из {2}", "items-per-page-options": [25, 50, 100]}',
      :items-per-page='25'
    )
      template(v-slot:progress)
        v-progress-linear(color="accent" indeterminate)
      template(v-slot:no-data)
        v-skeleton-loader(v-if='false', type='table-row-divider@5, table-row')
        .text-center.no-data_background.pa-2.pb-5(v-if='true')
          h2.mt-4 здесь пока ничего нет
      template(v-slot:item.agency_id='{ item }')
        .id-icon(@click='copyId(item.agency_id)')
      template(v-slot:item.account_id='{ item }')
        .id-icon(@click='copyId(item.account_id)')
      template(v-slot:item.campaign_id='{ item }')
        .id-icon(@click='copyId(item.campaign_id)')
      template(v-slot:item.creative_id='{ item }')
        .id-icon(@click='copyId(item.creative_id)')
      template(
        v-slot:footer
      )
        .all_data_container
          v-btn.load_all_data(
            @click='getBigData',
            elevation='0',
            outlined,
            color='#BDBDBD'
          ) Загрузить все данные
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import export_table from '@/components/export_table';
import loading_mixin from '@/mixins/loading_mixin'
import {GET_CITIES, GET_PLATFORM_TYPES, GET_PUBLISHERS, GET_UNITS} from "@/store/const/map";
import {GET_BID_TYPES, GET_CAMPAIGN_LIST} from "@/store/const/campaign";
import {AGENCY_DATA} from "@/store/const/root";
import {GET_ACCOUNT_DATA} from "@/store/const/agency";
import {CLEAR_ANALYTICS, GET_ANALYTICS, GET_FORECAST} from "@/store/const/report";
import {NEW_MODE, SET_SESSION} from "@/store/const/auth";

export default {
  name: 'CampaignReport',
  components: {export_table},
  mixins: [loading_mixin],
  props: {
    interfaceName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      reports: [
        {value: 'analytics', label: 'Аналитика'},
        {value: 'forecaster', label: 'Прогнозатор'},
      ],
      reportType: 'analytics',
      date: [],
      menu: false,
      campaign: '',
      agency: '',
      advertiser: '',
      city: null,
      supplier: [],
      surface_ids: [''],
      names: [
        {value: 'agency_id', label: 'ID агентства'},
        {value: 'agency_name', label: 'Название агентства'},
        {value: 'account_id', label: 'ID рекламодателя'},
        {value: 'account_name', label: 'Название рекламодателя'},
        {value: 'campaign_id', label: 'ID кампании'},
        {value: 'campaign_name', label: 'Название кампании'},
        {value: 'creative_id', label: 'ID креатива'},
        {value: 'creative_name', label: 'Название креатива'},
        {value: 'creative_size', label: 'Размер креатива'},
        {value: 'provider_id', label: 'SSP'},
        {value: 'surface_gid', label: 'GID поверхности'},
        {value: 'surface_id', label: 'ID поверхности'},
        {value: 'surface_type', label: 'Тип поверхности'},
        {value: 'surface_city', label: 'Город'},
        {value: 'surface_venue', label: 'Формат поверхности'},
        {value: 'event_date', label: 'Дата'},
        {value: 'event_time', label: 'Время'},
        {value: 'hours', label: 'Часы'},
        {value: 'surface_address', label: 'Адрес'},
        {value: 'publisher_id', label: 'Поставщик поверхности'},
        /*{ value: 'quantity', label: 'Охват' },
        { value: 'publisher_id', label: 'ID поставщика' },
        { value: 'placement_id', label: 'ID размещения' },
        { value: 'bid_request_id', label: 'ID запроса' },
      { value: 'surface_address', label: 'Адрес' },*/
      ],
      groups: [
        {value: 'agency_id', label: 'Агентства'},
        {value: 'account_id', label: 'Рекламодатели'},
        {value: 'campaign_id', label: 'Кампании'},
        {value: 'creative_id', label: 'Креативы'},
        {value: 'creative_size', label: 'Размер креатива'},
        {value: 'provider_id', label: 'SSP'},
        {value: 'surface_gid', label: 'GID поверхности'},
        {value: 'surface_id', label: 'ID поверхности'},
        {value: 'surface_type', label: 'Тип поверхности'},
        {value: 'surface_city', label: 'Город'},
        {value: 'surface_venue', label: 'Формат поверхности'},
        {value: 'event_date', label: 'Дата'},
        {value: 'event_time', label: 'Время'},
        {value: 'hours', label: 'Часы'},
        {value: 'surface_address', label: 'Адрес'},
        {value: 'publisher_id', label: 'Поставщик поверхности'},
      ],
      group: [],
      filter_types: [
        {value: 'campaign_id', label: 'Рекламная кампания'},
        {value: 'bid_request_id', label: 'ID запроса'},
        {value: 'agency_id', label: 'ID Агентства'},
        {value: 'account_id', label: 'ID Рекламодателя'},
        {value: 'bid_strategy', label: 'Показы/Рейтинг'},
        {value: 'creative_id', label: 'ID Креатива'},
        {value: 'event', label: 'Событие'},
        {value: 'publisher_id', label: 'Поставщик поверхности'},
        {value: 'surface_type', label: 'Тип Поверхности'},
        {value: 'surface_gid', label: 'Идентификатор поверхности'},
        {value: 'surface_city', label: 'Город'},
        {value: 'surface_venue', label: 'Формат поверхности'},
        {value: 'bid_type', label: 'Тип стратегии'}
      ],
      filterSelectFields: ['bid_strategy', 'publisher_id', 'surface_type', 'surface_city', 'surface_venue', 'campaign_id'],
      operations: [
        {label: '=', value: '='},
        {label: '≠', value: 'ne'},
      ],
      filters: [],
      format: 1,
      filter_menu: false,
      filter_values: {
        bid_strategy: [],
        publisher_id: [],
        surface_type: [],
        surface_city: [],
        surface_venue: [],
        campaign_id: this.getCampaigns,
      },
      requestLimit: null,
    };
  },
  watch: {
    reportType() {
      this.agency = "";
      this.advertiser = "";
      this.group = "";
      this.filters = [];
      this.city = null;
      this.supplier = [];
      this.requestLimit = null;
      this.CLEAR_ANALYTICS();
    },
  },
  computed: {
    ...mapGetters('Agency', ['getAccountData']),
    ...mapGetters('User', ['getUserInfo']),
    ...mapGetters('Root', ['getAgencies', 'getAllAccounts']),
    ...mapGetters('Report', ['getAnalytics']),
    ...mapGetters('Map', ['getCities', 'getPlatformTypes', 'getPublishers', 'getUnits', 'getFormats']),
    ...mapGetters('Campaign', ['getBidTypes', 'getCampaigns']),
    mappedAnalytics() {
      const {fields = [], data = []} = this.getAnalytics;
      if (!data) {
        return []
      }
      return data.map((row) => Object.fromEntries(
        fields.map((field, index) => [field, row[index]])
      ));
    },
    analyticsFormatted() {
      if (this.reportType === 'analytics') {
        return this.mappedAnalytics
      }
      if (!this.getAnalytics.length) {
        return []
      }
      return this.getAnalytics.filter(analytic => {
        let key = this.getUnits.findIndex(unit => unit.id === analytic.surface_id)
        if (key !== -1) {
          analytic.surface_gid = this.getUnits[key]['gid']
        }
        delete analytic.surface_id
        return analytic
      })
    },
    getHeaders() {
      if (this.analyticsFormatted.length) {
        return Object.keys(this.analyticsFormatted[0])
        .flatMap((header) => {
          if (header === 'tracking_id') {
            return [];
          }
          const founded_group = this.names.find((group) => group.value === header);
          let label = '';
          if (!founded_group) {
            switch (header) {
              case 'budget':
                label = 'Бюджет';
                break;
              case 'count':
                label = 'Количество';
                break;
              default:
                label = header;
            }
          } else {
            label = founded_group.label;
          }
          return {text: label, value: header};
        })
        .filter((header) => header)
      }
      return [];
    },
    dateFormatted() {
      let total = ''
      if (!this.date.length) {
        return total
      }
      total += this.date[0] < this.date[1]
        ? this.$moment(this.date[0]).format('DD.MM.YYYY')
        : this.$moment(this.date[1]).format('DD.MM.YYYY');
      total += ' - '
      total += this.date[0] < this.date[1]
        ? this.$moment(this.date[1]).format('DD.MM.YYYY')
        : this.$moment(this.date[0]).format('DD.MM.YYYY');
      return total;
    },
  },
  created() {
    this.date[0] = this.$moment().startOf('month').format('YYYY-MM-DD')
    this.date[1] = this.$moment().endOf('month').format('YYYY-MM-DD')
  },
  async mounted() {
    this.setLoadingActions()
    this.CLEAR_ANALYTICS()
    if (this.$route.meta.session_type === 'root') {
      await this.AGENCY_DATA();
    }
    if (this.$route.meta.session_type === 'agency') {
      await this.GET_ACCOUNT_DATA();
    }
    if (this.$route.meta.session_type === 'account') {
      await this.GET_CAMPAIGN_LIST({
        status: 'all'
      });
    }
    await Promise.all([
      this.GET_CITIES(),
      this.GET_PUBLISHERS(),
      this.GET_PLATFORM_TYPES(),
      this.GET_BID_TYPES(),
    ]);

    this.filter_values = {
      surface_city: this.getCities,
      publisher_id: this.getPublishers,
      surface_venue: this.getFormats,
      surface_type: this.getPlatformTypes,
      bid_strategy: this.getBidTypes,
      campaign_id: this.getCampaigns,
    };
  },
  methods: {
    ...mapActions('Map', [GET_CITIES, GET_PUBLISHERS, GET_PLATFORM_TYPES, GET_UNITS]),
    ...mapActions('Root', [AGENCY_DATA]),
    ...mapActions('Agency', [GET_ACCOUNT_DATA]),
    ...mapActions('Campaign', [GET_BID_TYPES, GET_CAMPAIGN_LIST]),
    ...mapActions('Report', [GET_ANALYTICS, GET_FORECAST]),
    ...mapActions('Auth', [NEW_MODE]),
    ...mapMutations('Report', [CLEAR_ANALYTICS]),
    ...mapMutations('Auth', [SET_SESSION]),
    setLoadingActions() {
      this.actions = [
        GET_CITIES,
        GET_PUBLISHERS,
        GET_PLATFORM_TYPES,
        AGENCY_DATA,
        GET_ACCOUNT_DATA,
        GET_CAMPAIGN_LIST,
        GET_ANALYTICS,
        GET_FORECAST,
        NEW_MODE,
        CLEAR_ANALYTICS
      ]
    },
    async getAnalyticsRequest() {
      if (!this.group.length) {
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          text: 'Не выбрана группировка',
        });
        return;
      }
      if (this.filters.some((filter) => !filter.field || !filter.operation || !filter.values.length)) {
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          text: 'Добавлен пустой фильтр',
        });
        return;
      }
      let params = {
        account: this.advertiser,
        filter: this.filters,
        from: this.$moment.utc(this.date[0]).valueOf(),
        to: this.$moment.utc(this.date[1]).endOf('day').valueOf(),
        group: this.group,
        limit: this.requestLimit,
        format: this.format
      };
      params.session_type = 'account'
      await this.GET_ANALYTICS(params);
      if (this.getAnalytics.data === null) {
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          text: 'Данных нет',
        });
      }
    },
    async getForecastRequest() {
      let ids = [];
      if (this.city || this.surface_ids.length > 0) {
        await this.getUnitsRequest();
        ids = this.getUnits.map((unit) => unit.id);
      }
      let params = {
        filter: [
          {
            field: 'surface_id',
            operation: '=',
            values: ids,
          },
        ],
        from: this.$moment.utc(this.date[0]).valueOf(),
        to: this.$moment.utc(this.date[1]).endOf('day').valueOf(),
        group: ['surface_id'],
        limit: this.requestLimit,
      };
      if (this.supplier.length) {
        params.filter.push({
          field: 'publisher_id',
          operation: '=',
          values: this.supplier,
        });
      }
      params.session_type = 'account'
      await this.GET_FORECAST(params);
      if (this.isError(GET_FORECAST)) {
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          text: 'Данных нет',
        });
      }
    },
    deleteFilter(index) {
      this.$delete(this.filters, index);
    },
    onClickOutside(e) {
      if (e.target.className.includes('v-list-item') || e.target.className.includes('mdi-checkbox')) {
        return;
      }
      this.filter_menu = false;
    },
    async changeAgency(val) {
      this.campaign = '';
      if (this.getUserInfo.role === 'Root') {
        let params = {
          id: val,
          type: 'agency',
          session_type: 'root'
        };
        await this.NEW_MODE(params);
        this.SET_SESSION({type: 'account', session: null})
        await this.GET_ACCOUNT_DATA();
      }
    },
    async changeAdvertiser(val) {
      this.campaign = '';
      let params = {
        id: val,
        type: 'account',
        session: sessionStorage.getItem('sessionAgency'),
      };
      await this.NEW_MODE(params);
      await this.GET_CAMPAIGN_LIST({
        status: 'all',
      });
      this.filter_values.campaign_id = this.getCampaigns;
    },
    async getUnitsRequest() {
      await this.GET_UNITS({
        types: [],
        publisherIds: [],
        cityIds: this.city ? [this.city] : [],
        venues: [],
        gids: this.surface_ids
      });
      if (this.isError(GET_UNITS)) {
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          text: 'Ошибка при получении списка',
        })
      }
      if (!this.getUnits.length) {
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          text: 'Поверхности по заданным параметрам не найдены',
        });
      }
    },
    onFilterChange(val, index) {
      if (val === 'event') {
        this.filters[index].values = ['win'];
        return;
      }
      this.filters[index].values = [];
    },
    async getBigData() {
      this.requestLimit = 100000;
      this.reportType === "analytics" ? await this.getAnalyticsRequest() : await this.getForecastRequest()
    },
    copyId(id) {
      this.$copyText(id)
      this.$notify({
        type: 'success',
        title: 'Успешно',
        text: 'Успешно скопировано',
      })
    }
  },
};
</script>

<style scoped>
.filters__container {
  position: relative;
}

.id-icon {
  width: 25px;
  height: 25px;
  background: url('~@/assets/SVG/icons/id-icon.svg') 50% no-repeat;
  cursor: pointer;
}

.filters {
  width: 100%;
  position: absolute;
  top: 40px;
  padding: 10px;
}

.filter_btn {
  position: relative;
  border: solid 1px #dcdfe7;
  border-radius: 4px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
}

.filter_btn_icon {
  position: absolute;
  right: 10px;
}

.dropdown_button {
  position: relative;
}

.dropdown_button_icon {
  position: absolute;
  right: 5px;
}

.filter_btn_active {
  border-color: #4a55b0;
}

.filter_btn_active_icon {
  transform: rotate(180deg);
}

.filter_btn:hover {
  background: white;
}

.identifiers {
  display: flex;
  align-items: center;
  justify-content: center;
}

.add_button {
  color: #fff;
  background: #4a55b0;
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}

.all_data_container {
  position: relative;
}

.load_all_data {
  cursor: pointer;
  position: absolute;
  font-weight: bold;
  top: 15px;
  left: 20px;
}
</style>
