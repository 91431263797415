<template lang="pug">
campaignReport(interfaceName='account')
</template>

<script>
import campaignReport from '@/components/report/campaignReport';

export default {
	name: 'Reports',
	components: {campaignReport},
};
</script>

<style scoped></style>
