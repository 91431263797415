<template lang="pug">
  v-menu(bottom, width='210', v-model='visible', offset-y, :close-on-content-click="false", :nudge-left="20", :nudge-bottom='5')
    template(v-slot:activator='{ on, attrs }')
      div(style='display: flex; align-items: right; justify-content: right')
        tooltip(text='Выгрузить таблицу')
          v-btn.mb-2(
            color='accent',
            width='56',
            height='40',
            small,
            v-on='on',
            v-bind='attrs',
            v-if='full == false'
          )
            v-icon mdi-download
          v-btn(
            height='40',
            color='accent',
            v-on='on',
            v-bind='attrs',
            v-if='full'
          ) Выгрузить таблицу
            v-icon.pt-1(small, right) mdi-download

    v-card(elevation='0')
      v-list-item
        v-list-item-content
          .fz-14 Выберите формат экспорта
      v-card-actions.flex-center.ml-4.mr-2
        v-btn(elevation='0', small, color='accent', @click='exportTable("csv")') csv
        v-btn(elevation='0', small, color='accent', @click='exportTable("xlsx")') xlsx
        v-btn(elevation='0', small, color='accent', @click='exportTable("xls")') xls
</template>
<script>
import * as XLSX from 'xlsx/xlsx.mjs';
import tooltip from '@/components/tooltip'

export default {
  components: {tooltip},
  props: {
    data: {
      type: Array | Object,
      required: true,
    },
    filename: {
      type: String,
      default: '',
    },
    full: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    exportTable(type) {
      let data = this.data.data !== undefined ? this.data.data : this.data;
      if (typeof this.data === 'object' && this.data.fields !== undefined) {
        data.unshift(this.data.fields);
      }
      let sheet = XLSX.utils.json_to_sheet(data);
      let book = XLSX.utils.book_new();
      book.SheetNames.push('test')
      book.Sheets['test'] = sheet
      return XLSX.writeFile(book, this.filename + ' ' + new Date().toLocaleString('ru') + '.' + type);
    }
  }
};
</script>

<style lang="scss">
.flex-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
